import React from 'react';
import { useAppState } from '../../hooks/useAppState';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ProductList from '../ProductList/ProductList';
import DataManager from '../DataManager/DataManager';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import FilterModal from '../FilterModal/FilterModal';
import HelpModal from '../HelpModal/HelpModal';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const MainContent: React.FC = () => {
  const {
    searchTerm,
    filters,
    loading,
    buttonLoading,
    sortBy,
    filterModalOpen,
    helpModalOpen,
    uniqueShopTypes,
    uniqueCategories,
    filteredProducts,
    handleDataFetched,
    handleSearch,
    handleFilter,
    handleResetApplication,
    handleResetHome,
    handleSortByAbsolut,
    handleSortByPercentage,
    handleShowHelp,
    handleCloseHelp,
    setFilterModalOpen,
  } = useAppState();

  return (
    <>
      <Header onSearch={handleSearch} onFilter={handleFilter} onReset={handleResetHome} onShowHelp={handleShowHelp} />
      <div className="main-content">
        <DataManager onDataFetched={handleDataFetched} />
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        ) : (
          <ProductList products={filteredProducts} searchTerm={searchTerm} filters={filters} sortBy={sortBy} />
        )}
      </div>
      <Footer
        onSortByAbsolut={handleSortByAbsolut}
        onSortByPercentage={handleSortByPercentage}
        onResetHome={handleResetHome}
        onResetApplication={handleResetApplication}
        onShowFilter={() => setFilterModalOpen(true)}
      />
      {buttonLoading && <LoadingOverlay />}
      <FilterModal
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        onApplyFilters={handleFilter}
        initialFilters={filters}
        uniqueShopTypes={uniqueShopTypes}
        uniqueCategories={uniqueCategories}
      />
      <HelpModal open={helpModalOpen} onClose={handleCloseHelp} />
    </>
  );
};

export default MainContent;