import React from 'react';
import { useUser, SignedIn, SignedOut, UserButton } from "@clerk/clerk-react";
import { Link } from 'react-router-dom';
import logo from '../../assets/cargobike.svg';
import questionIcon from '../../assets/icons/question.svg';
import SearchBar from '../SearchBar/SearchBar';
import { FilterState } from '../../types/FilterState';

interface HeaderProps {
  onSearch: (searchTerm: string) => void;
  onFilter: (filters: FilterState) => void;
  onReset: () => void;
  onShowHelp: () => void;
}

const Header: React.FC<HeaderProps> = ({ onSearch, onFilter, onReset, onShowHelp }) => {
  const { isLoaded, user } = useUser();

  const handleLogoClick = () => {
    onReset();
  };

  return (
    <header className="bg-green-600 p-2 flex justify-between items-center sticky top-0 z-10">
      <div className="flex items-center cursor-pointer" onClick={handleLogoClick}>
        <img src={logo} alt="Logo" className="h-8 w-8" />
      </div>
      <div className="flex items-center space-x-4 flex-grow mx-4">
        <SearchBar onSearch={onSearch} />
      </div>
      <div className="flex items-center space-x-2">
        <button className="flex items-center justify-center w-8 h-8 bg-white rounded-full" onClick={onShowHelp}>
          <img src={questionIcon} alt="Help" className="w-4 h-4 text-green-600" />
        </button>
        {isLoaded && (
          <>
            <SignedIn>
              <UserButton afterSignOutUrl="/" />
              {user && <span className="text-white">{user.firstName || user.username}</span>}
            </SignedIn>
            <SignedOut>
              <Link to="/sign-in" className="bg-white text-green-600 px-3 py-1 rounded">
                Sign In
              </Link>
            </SignedOut>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;