import React, { useEffect } from 'react';

export const CustomSignUp = () => {
  useEffect(() => {
    window.location.href = 'https://accounts.omfietser.nl/sign-up';
  }, []);

  return <div>Redirecting to sign up...</div>;
};

export const CustomSignIn = () => {
  useEffect(() => {
    window.location.href = 'https://accounts.omfietser.nl/sign-in';
  }, []);

  return <div>Redirecting to sign in...</div>;
};