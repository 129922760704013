import React from 'react';
import LazyLoad from 'react-lazyload';
import { Product } from '../../types/Product';
import placeholderImage from '../../assets/cargobike_green.svg';
import placeholder_supermarket from '../../assets/ah.svg';

interface ProductItemProps {
  product: Product;
  onToggleFavorite: (productId: string) => void;
  isFavorite: boolean;
}

const ProductItem: React.FC<ProductItemProps> = ({ product, onToggleFavorite, isFavorite }) => {
  const productImageUrl = product.webshopId
    ? `https://omfietser.nl/images/${product.webshopId}.avif`
    : placeholderImage;

  const supermarketLogo = placeholder_supermarket;

  return (
    <div className="bg-white p-2 rounded-lg shadow-md flex">
      {/* Left Column: Image, Brand, and Supermarket Logo */}
      <div className="flex flex-col justify-between items-start mr-4">
        <div>
          <img src={supermarketLogo} alt={product.shopType} className="h-8 w-8 object-contain" />
        </div>
        <div className="mb-2">
          <LazyLoad height={128} offset={100} placeholder={<img src={placeholderImage} alt={product.title} className="h-32 w-32 object-contain" />}>
            <img src={productImageUrl} alt={product.title} className="h-32 w-32 object-contain" />
          </LazyLoad>
        </div>
        <p className="text-gray-600 text-xs font-light mb-2">{product.salesUnitSize || 'N/A'}</p>
      </div>

      {/* Right Column: Product Details */}
      <div className="flex flex-col justify-between items-start w-full">
        <div className="flex justify-between items-start w-full">
          <h3 className="font-semibold text-lg mb-2 text-left hyphens-auto">{product.title || 'N/A'}</h3>
          {/* Moved favorite button here for better visibility */}
          <button
            className={`ml-2 text-2xl ${isFavorite ? 'text-red-600' : 'text-gray-400'}`}
            onClick={() => onToggleFavorite(product._id.$oid)}
            aria-label="Toggle Favorite"
          >
            {isFavorite ? '❤️' : '♡'}
          </button>
        </div>
        {product.isBonus && (
          <p className="inline-block rounded-full bg-red-500 text-white px-4 mb-2 py-1 sm:text-lg xl:text-xl font-semibold">{product.discountLabelDefaultDescription || 'N/A'}</p>
        )}
        <div className="text-gray-800 font-bold mb-2 text-left w-full">
          {product.isBonus ? (
            <>
              <span className="text-green-600 sm:text-2xl md:text-3xl font-semibold">Nu €{product.discountPrice?.toFixed(2) || 'N/A'}</span>
              <span className="text-gray-600 ml-6 text-sm font-light">Normaal €{product.priceBeforeBonus?.toFixed(2) || 'N/A'}</span>
            </>
          ) : (
            <span>€{product.priceBeforeBonus?.toFixed(2) || 'N/A'}</span>
          )}
        </div>
        {product.isBonus && (
          <div className="text-green-600 mt-2 flex">
            <p className="text-gray-800 font-semibold">Korting: €{product.discountAbsolut?.toFixed(2) || 'N/A'}</p>
            <span className="text-gray-800 ml-2">({product.discountPercentage?.toFixed(2) || 'N/A'}%)</span>
          </div>
        )}
        {product.isBonus && (
          <p className="text-gray-600 text-xs font-light mt-2">{product.bonusStartDate || 'N/A'} - {product.bonusEndDate || 'N/A'}</p>
        )}
        <p className="text-gray-600 text-xs font-light mt-4 mb-2">{product.mainCategory || 'N/A'}</p>
      </div>
    </div>
  );
};

export default ProductItem;