import { useState, useCallback, useMemo } from 'react';
import { FilterState } from '../types/FilterState';
import { Product } from '../types/Product';

// Configurable flag to show or hide the help modal on startup
const showHelpModalOnStartup = false;

interface IndexedProducts {
  products: Product[];
  index: {
    byId: Map<string, Product>;
    byTitle: Map<string, Product[]>;
    byDiscountAbsolut: Product[];
    byDiscountPercentage: Product[];
    byShopType: Map<string, Product[]>;
    byMainCategory: Map<string, Product[]>;
    byIsBonus: Map<boolean, Product[]>;
  };
}

export const useAppState = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState<FilterState>({ isBonus: true });
  const [indexedProducts, setIndexedProducts] = useState<IndexedProducts | null>(null);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [sortBy, setSortBy] = useState<'absolut' | 'percentage'>('absolut');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [uniqueShopTypes, setUniqueShopTypes] = useState<string[]>([]);
  const [uniqueCategories, setUniqueCategories] = useState<string[]>([]);
  const [dataFetched, setDataFetched] = useState(false);

  const handleDataFetched = useCallback((data: IndexedProducts) => {
    if (!dataFetched) {
      setIndexedProducts(data);
      setLoading(false);
      
      const shopTypes = Array.from(new Set(data.products.map(product => product.shopType)));
      const categories = Array.from(new Set(data.products.map(product => product.mainCategory)));

      setUniqueShopTypes(shopTypes);
      setUniqueCategories(categories);

      // Show help modal based on the configurable flag
      if (showHelpModalOnStartup) {
        setHelpModalOpen(true);
      }

      setDataFetched(true);
    }
  }, [dataFetched]);

  const handleSearch = useCallback((searchTerm: string) => {
    setSearchTerm(searchTerm);
  }, []);

  const handleFilter = useCallback((selectedFilters: FilterState) => {
    console.log('Filters applied:', selectedFilters);
    setFilters(selectedFilters);
  }, []);

  const handleResetApplication = useCallback(() => { 
    // Clear Service Worker cache
    if ('caches' in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    }

    // Reload the app to check for a new version after clearing cache
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration) {
          // Update the service worker to get the latest version
          registration.update().then(() => {
            console.log("Service worker updated. Reloading app...");
            window.location.reload(); // Reload the page immediately after update
          });
        } else {
          // No service worker registered, just reload
          window.location.reload(); // Fallback reload if no SW registered
        }
      });
    } else {
      // If service workers aren't supported, just reload
      window.location.reload(); 
    }

    // As a fallback, reload the app after clearing cache
    setTimeout(() => {
      console.log("Reloading app...");
      window.location.reload();
    }, 1000); // Add a timeout to reload after 1 second if nothing else happens
  }, []);

  const handleResetHome = useCallback(() => {
    setButtonLoading(true);
    setTimeout(() => {
      if (indexedProducts) {
        setSearchTerm('');
        setFilters({ isBonus: true });
        setSortBy('absolut');
        setFilterModalOpen(false);
      }
      setButtonLoading(false);
    }, 100);
  }, [indexedProducts]);

  const handleSortByAbsolut = useCallback(() => {
    setButtonLoading(true);
    setTimeout(() => {
      setSortBy('absolut');
      setButtonLoading(false);
    }, 100);
  }, []);

  const handleSortByPercentage = useCallback(() => {
    setButtonLoading(true);
    setTimeout(() => {
      setSortBy('percentage');
      setButtonLoading(false);
    }, 100);
  }, []);

  const handleShowHelp = useCallback(() => {
    setHelpModalOpen(true);
  }, []);

  const handleCloseHelp = useCallback(() => {
    setHelpModalOpen(false);
  }, []);

  const filteredProducts = useMemo(() => {
    if (!indexedProducts) return [];

    let filtered = indexedProducts.products
      .filter((product) => product.title.toLowerCase().includes(searchTerm.toLowerCase()))
      .filter((product) => (product.discountPercentage ?? 0) >= (filters.minDiscount || 0));

    if (filters.shopType && filters.shopType.length > 0) {
      filtered = filters.shopTypeIncludeMode
        ? filtered.filter(product => filters.shopType?.includes(product.shopType))
        : filtered.filter(product => !filters.shopType?.includes(product.shopType));
    }

    if (filters.mainCategory && filters.mainCategory.length > 0) {
      filtered = filters.categoryIncludeMode
        ? filtered.filter(product => filters.mainCategory?.includes(product.mainCategory))
        : filtered.filter(product => !filters.mainCategory?.includes(product.mainCategory));
    }

    if (filters.isBonus) {
      filtered = filtered.filter(product => product.isBonus);
    }

    return sortBy === 'absolut'
      ? filtered.sort((a, b) => (b.discountAbsolut || 0) - (a.discountAbsolut || 0))
      : filtered.sort((a, b) => (b.discountPercentage || 0) - (a.discountPercentage || 0));
  }, [indexedProducts, searchTerm, filters, sortBy]);

  return {
    searchTerm,
    filters,
    indexedProducts,
    loading,
    buttonLoading,
    sortBy,
    filterModalOpen,
    helpModalOpen,
    uniqueShopTypes,
    uniqueCategories,
    dataFetched,
    filteredProducts,
    handleDataFetched,
    handleSearch,
    handleFilter,
    handleResetApplication,
    handleResetHome,
    handleSortByAbsolut,
    handleSortByPercentage,
    handleShowHelp,
    handleCloseHelp,
    setFilterModalOpen,
  };
};