import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { SignedIn, SignedOut, RedirectToSignIn, useUser, UserProfile } from '@clerk/clerk-react';
import { CustomSignUp, CustomSignIn } from '../ClerkCustomizations/ClerkCustomizations';
import MainContent from '../MainContent/MainContent';
import SplashScreen from '../SplashScreen/SplashScreen';

export const AppRouter: React.FC = () => {
  const location = useLocation();
  const { isLoaded, isSignedIn, user } = useUser();

  React.useEffect(() => {
    console.log('Current route:', location.pathname);
    console.log('isLoaded:', isLoaded);
    console.log('isSignedIn:', isSignedIn);
    console.log('User:', user);
  }, [location, isLoaded, isSignedIn, user]);

  if (!isLoaded) {
    return <SplashScreen />;
  }

  return (
    <Routes>
      <Route path="/sign-up/*" element={<CustomSignUp />} />
      <Route path="/sign-in/*" element={<CustomSignIn />} />
      <Route 
        path="/user-profile" 
        element={
          <>
            <SignedIn>
              <UserProfile />
            </SignedIn>
            <SignedOut>
              <RedirectToSignIn />
            </SignedOut>
          </>
        } 
      />
      <Route path="/" element={<MainContent />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};