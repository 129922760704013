import React, { useState, useMemo } from 'react';
import { debounce } from 'lodash';

interface SearchBarProps {
  onSearch: (searchTerm: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [value, setValue] = useState('');

  const debouncedSearch = useMemo(() => debounce((term) => onSearch(term), 1000), [onSearch]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setValue(term);
    debouncedSearch(term);
  };

  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
      placeholder="Search..."
      className="p-2 rounded-md border border-gray-300 w-full"
    />
  );
};

export default SearchBar;
