import React from 'react';
import appLogo from '../../assets/cargobike.svg'; // Adjust the path if needed

const SplashScreen: React.FC = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-green-600 z-50">
      <div className="text-center">
        <img src={appLogo} alt="App Logo" className="w-32 h-32 mx-auto mb-4" />
        <h1 className="text-2xl mb-8 font-extrabold text-white mt-2 mx-4 sm:mx-8 md:mx-12 lg:mx-16 xl:mx-20">De moeite van het omfietsen waard...</h1>
        <p className="text-xl font-semibold text-white">Loading...</p>
      </div>
    </div>
  );
};

export default SplashScreen;
