import React, { useMemo, useEffect, useState } from 'react';
import ProductItem from '../ProductItem/ProductItem';
import { Product } from '../../types/Product';
import { FilterState } from '../../types/FilterState';

interface ProductListProps {
  products: Product[];
  searchTerm: string;
  filters: FilterState;
  sortBy: 'absolut' | 'percentage';
}

const ProductList: React.FC<ProductListProps> = React.memo(({ products, searchTerm, filters, sortBy }) => {
  console.log('Products:', products);
  console.log('Search Term:', searchTerm);
  console.log('Filters:', filters);

  const [favoriteProducts, setFavoriteProducts] = useState<string[]>(() => {
    // Retrieve favorites from localStorage or initialize empty array
    const savedFavorites = localStorage.getItem('favoriteProducts');
    return savedFavorites ? JSON.parse(savedFavorites) : [];
  });

  const handleToggleFavorite = (productId: string) => {
    setFavoriteProducts((prevFavorites) => {
      const updatedFavorites = prevFavorites.includes(productId)
        ? prevFavorites.filter((id) => id !== productId)
        : [...prevFavorites, productId];
      
      // Save updated favorites to localStorage
      localStorage.setItem('favoriteProducts', JSON.stringify(updatedFavorites));
      return updatedFavorites;
    });
  };

  const filteredProducts = useMemo(() => {
    return products
      .filter((product) => product.title.toLowerCase().includes(searchTerm.toLowerCase()))
      .filter((product) => {
        const { minDiscount = 0, category = '', storeName = '', isDiscounted = false } = filters;
        return (
          (product.discountPercentage ?? 0) >= minDiscount &&
          (category === '' || product.mainCategory === category) &&
          (storeName === '' || product.shopType === storeName) &&
          (!isDiscounted || product.isBonus)
        );
      })
      .sort((a, b) => {
        const isAFavorite = favoriteProducts.includes(a._id.$oid);
        const isBFavorite = favoriteProducts.includes(b._id.$oid);
        if (isAFavorite && !isBFavorite) return -1;
        if (!isAFavorite && isBFavorite) return 1;

        if (sortBy === 'absolut') {
          return (b.discountAbsolut || 0) - (a.discountAbsolut || 0);
        } else {
          return (b.discountPercentage || 0) - (a.discountPercentage || 0);
        }
      });
  }, [products, searchTerm, filters, sortBy, favoriteProducts]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [filteredProducts]);

  return (
    <div className="px-2">
      <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {filteredProducts.map((product) => (
          <ProductItem
            key={product._id.$oid}
            product={product}
            onToggleFavorite={handleToggleFavorite}
            isFavorite={favoriteProducts.includes(product._id.$oid)}
          />
        ))}
      </ul>
    </div>
  );
});

export default ProductList;