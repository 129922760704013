import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

interface HelpModalProps {
  open: boolean;
  onClose: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '400px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  outline: 0,
  maxHeight: '90vh',
  overflowY: 'auto',
};

const HelpModal: React.FC<HelpModalProps> = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="help-modal-title"
      aria-describedby="help-modal-description"
    >
      <Box sx={style} className="bg-white rounded-lg shadow-lg p-6">
        <Box className="flex justify-between items-center mb-4">
          <Typography id="help-modal-title" variant="h6" component="h2" className="text-xl font-bold">
            Release info & Help
          </Typography>
          <IconButton onClick={onClose} className="text-gray-600">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box id="help-modal-description" className="text-gray-700">
          <p className="mb-4 !font-extrabold">Welkom bij de Omfietser!</p>
          <Typography className="!mb-4">🚀 Nieuwe Release: Versie 1.9.0</Typography>
          <Typography className="!mb-4 !font-bold">Wat is er nieuw</Typography>
          <Typography className="!mb-4">We zijn verheugd om de release van versie 1.8.0 van onze applicatie aan te kondigen! Deze release brengt belangrijke nieuwe functies en verbeteringen.</Typography>
          
          <Typography className="!mb-4 !font-bold">Nieuwe Functies</Typography>
          <ul className="list-disc list-outside space-y-2 pl-5">
            <li><strong>Favoriten</strong>: Selecteer met het hartje je favorite producten. Deze verschijnen bovenaan in de lijst.</li>
          </ul>

          <Typography className="!mb-4 !mt-6 !font-bold">Verbeteringen</Typography>
          <ul className="list-disc list-outside space-y-2 pl-5">
            <li><strong>Prestaties</strong>: Significant verbeterde laadtijden en app-responsiviteit.</li>
            <li><strong>Gebruikersinterface</strong>: Verfijnde UI-elementen voor een betere gebruikerservaring.</li>
            <li><strong>Stabiliteit</strong>: Opgeloste problemen met ongewenste pagina-herladingen en gegevensverversing.</li>
          </ul>

          <Typography className="!mb-4 !mt-6 !font-bold">Privacy en Gegevensbescherming</Typography>
          <ul className="list-disc list-outside space-y-2 pl-5">
            <li><strong>GDPR-compliance</strong>: Verbeterde maatregelen om aan de nieuwste privacystandaarden te voldoen.</li>
            <li><strong>Transparantie</strong>: Duidelijkere informatie over gegevensgebruik en -opslag binnen de app.</li>
          </ul>

          <Typography className="!my-4">We waarderen uw feedback enorm. Als u suggesties heeft of problemen ondervindt, neem dan contact met ons op via hello@omfietser.nl.</Typography>

          <Typography className="!mb-4 !mt-8 !font-bold">Gebruikshandleiding:</Typography>
          <ul className="list-disc list-outside space-y-2 pl-5">
            <li>Gebruik de zoekbalk om producten op naam te vinden.</li>
            <li>Gebruik de filterknop om producten te filteren op winkelaanbod, categorie of bonusstatus.</li>
            <li>Sorteer producten op absolute korting of percentage korting met de knoppen onderaan.</li>
            <li>Reset de filters en zoekopdracht door op de resetknop te klikken.</li>
            <li>De app werkt de productlijst automatisch elke dag bij om ervoor te zorgen dat je de nieuwste kortingen krijgt.</li>
            <li>Gebruik de Reset knop om de webapp volledig nieuw te laden vanaf de server.</li>
          </ul>
        </Box>
        <Box className="flex justify-center mt-6">
          <Button
            variant="contained"
            onClick={onClose}
            sx={{ backgroundColor: '#1b5e20', color: 'white', paddingX: 4, paddingY: 2, borderRadius: '4px' }}
          >
            Sluiten
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default HelpModal;