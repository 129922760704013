import React, { useEffect, useState } from 'react';
import { FilterState } from '../../types/FilterState';

interface FilterModalProps {
  open: boolean;
  onClose: () => void;
  onApplyFilters: (filters: FilterState) => void;
  initialFilters: FilterState;
  uniqueShopTypes: string[];
  uniqueCategories: string[];
}

const FilterModal: React.FC<FilterModalProps> = ({
  open,
  onClose,
  onApplyFilters,
  initialFilters,
  uniqueShopTypes,
  uniqueCategories,
}) => {
  const [filters, setFilters] = useState<FilterState>(initialFilters);
  const [categoryIncludeMode, setCategoryIncludeMode] = useState(initialFilters.categoryIncludeMode || false); // Default to exclude
  const [shopTypeIncludeMode, setShopTypeIncludeMode] = useState(initialFilters.shopTypeIncludeMode || false); // Default to exclude

  useEffect(() => {
    setFilters(initialFilters);
    setCategoryIncludeMode(initialFilters.categoryIncludeMode || false);
    setShopTypeIncludeMode(initialFilters.shopTypeIncludeMode || false);
  }, [initialFilters]);

  const handleApply = () => {
    onApplyFilters({ ...filters, categoryIncludeMode, shopTypeIncludeMode });
    onClose();
  };

  const handleReset = () => {
    setFilters({
      ...initialFilters,
      mainCategory: [],
      shopType: [],
      categoryIncludeMode: false,
      shopTypeIncludeMode: false,
    });
    setCategoryIncludeMode(false); // Reset to exclude
    setShopTypeIncludeMode(false); // Reset to exclude
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, filterKey: 'mainCategory' | 'shopType') => {
    const { value, checked } = e.target;
    setFilters((prevFilters) => {
      const selectedValues = prevFilters[filterKey] || [];
      if (checked) {
        return { ...prevFilters, [filterKey]: [...selectedValues, value] };
      } else {
        return { ...prevFilters, [filterKey]: selectedValues.filter((item) => item !== value) };
      }
    });
  };

  const handleClose = () => {
    handleApply();
  };

  if (!open) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex flex-col justify-between bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 shadow-lg w-full max-w-lg mx-auto mt-20 mb-20 rounded-lg overflow-auto flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Filter</h2>
            <button className="text-gray-600 hover:text-gray-900" onClick={handleClose}>
              &times;
            </button>
          </div>
          {/* Filter form elements */}
          <div className="mb-4">
            <label className="block text-sm text-left font-bold text-gray-700">Bonus</label>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={filters.isBonus}
                onChange={(e) => setFilters({ ...filters, isBonus: e.target.checked })}
                className="mr-2"
              />
            </div>
          </div>
          {/* Category Filter Group */}
          <div className="mb-4 text-left">
            <label className="mb-2 block text-sm font-bold text-gray-700">Main Category</label>
            <div className="relative w-24 mb-2">
              <div className="relative flex items-center h-6 bg-gray-300 rounded-full shadow-inner">
                <span
                  className={`absolute left-0 h-6 bg-green-800 rounded-full shadow transition-all duration-300 ease-in-out ${categoryIncludeMode ? 'translate-x-full w-1/2' : 'w-1/2'}`}
                ></span>
                <button
                  onClick={() => setCategoryIncludeMode(false)}
                  className={`relative z-10 flex-1 text-center text-xs transition-all duration-300 ease-in-out ${!categoryIncludeMode ? 'text-white' : 'text-transparent'}`}
                >
                  Exclude
                </button>
                <button
                  onClick={() => setCategoryIncludeMode(true)}
                  className={`relative z-10 flex-1 text-center text-xs transition-all duration-300 ease-in-out ${categoryIncludeMode ? 'text-white' : 'text-transparent'}`}
                >
                  Include
                </button>
              </div>
            </div>
            {uniqueCategories.map((category) => (
              <div key={category} className="flex items-center mt-2">
                <input
                  type="checkbox"
                  value={category}
                  checked={filters.mainCategory?.includes(category) || false}
                  onChange={(e) => handleCheckboxChange(e, 'mainCategory')}
                  className="mr-2"
                />
                <label className="text-sm text-gray-700">{category}</label>
              </div>
            ))}
          </div>
          {/* Shop Type Filter Group */}
          <div className="mb-4 text-left">
            <label className="mb-2 block text-sm font-bold text-gray-700">Shop Type</label>
            <div className="relative w-24 mb-2">
              <div className="relative flex items-center h-6 bg-gray-300 rounded-full shadow-inner">
                <span
                  className={`absolute left-0 h-6 bg-green-800 rounded-full shadow transition-all duration-300 ease-in-out ${shopTypeIncludeMode ? 'translate-x-full w-1/2' : 'w-1/2'}`}
                ></span>
                <button
                  onClick={() => setShopTypeIncludeMode(false)}
                  className={`relative z-10 flex-1 text-center text-xs transition-all duration-300 ease-in-out ${!shopTypeIncludeMode ? 'text-white' : 'text-transparent'}`}
                >
                  Exclude
                </button>
                <button
                  onClick={() => setShopTypeIncludeMode(true)}
                  className={`relative z-10 flex-1 text-center text-xs transition-all duration-300 ease-in-out ${shopTypeIncludeMode ? 'text-white' : 'text-transparent'}`}
                >
                  Include
                </button>
              </div>
            </div>
            {uniqueShopTypes.map((shopType) => (
              <div key={shopType} className="flex items-center mt-2">
                <input
                  type="checkbox"
                  value={shopType}
                  checked={filters.shopType?.includes(shopType) || false}
                  onChange={(e) => handleCheckboxChange(e, 'shopType')}
                  className="mr-2"
                />
                <label className="text-sm text-gray-700">{shopType}</label>
              </div>
            ))}
          </div>
          <div className="mb-4 text-left">
            <label className="block text-sm font-medium text-gray-700">Discount Price</label>
            <input
              type="range"
              min="0"
              max="100"
              value={filters.discountAbsolut ? filters.discountAbsolut[0] : 0}
              onChange={(e) => setFilters({ ...filters, discountAbsolut: [Number(e.target.value), filters.discountAbsolut ? filters.discountAbsolut[1] : 100] })}
              className="w-full"
            />
          </div>
          <div className="mb-4 text-left">
            <label className="block text-sm font-medium text-gray-700">Discount Percentage</label>
            <input
              type="range"
              min="0"
              max="100"
              value={filters.discountPercentage ? filters.discountPercentage[0] : 0}
              onChange={(e) => setFilters({ ...filters, discountPercentage: [Number(e.target.value), filters.discountPercentage ? filters.discountPercentage[1] : 100] })}
              className="w-full"
            />
          </div>
          <div className="mb-4 text-left">
            <label className="block text-sm font-medium text-gray-700">Price Before Bonus</label>
            <input
              type="range"
              min="0"
              max="100"
              value={filters.priceBeforeBonus ? filters.priceBeforeBonus[0] : 0}
              onChange={(e) => setFilters({ ...filters, priceBeforeBonus: [Number(e.target.value), filters.priceBeforeBonus ? filters.priceBeforeBonus[1] : 100] })}
              className="w-full"
            />
          </div>
        </div>
        {/* Buttons */}
        <div className="flex justify-end space-x-2 mt-4">
          <button
            className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
            onClick={handleApply}
          >
            Apply
          </button>
          <button
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-500"
            onClick={handleReset}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
