import React from 'react';
import homeIcon from '../../assets/icons/home.svg';
import euroIcon from '../../assets/icons/euro.svg';
import percentIcon from '../../assets/icons/percent.svg';
import refreshIcon from '../../assets/icons/refresh.svg';
import filterIcon from '../../assets/icons/filter.svg';

interface FooterProps {
  onSortByAbsolut: () => void;
  onSortByPercentage: () => void;
  onResetApplication: () => void; // Renamed from onResetFilters
  onResetHome: () => void;
  onShowFilter: () => void;
}

const Footer: React.FC<FooterProps> = ({
  onSortByAbsolut,
  onSortByPercentage,
  onResetApplication, // Renamed
  onResetHome,
  onShowFilter,
}) => {
  const buttonStyle = "flex-1 flex flex-col items-center justify-center h-full text-white focus:outline-none hover:bg-green-700 transition-colors";
  const iconStyle = "w-8 h-8 mb-1"; // Increased icon size
  const textStyle = "text-xs"; // You can change this to "text-sm" for larger text if needed

  return (
    <footer className="bg-green-600 sticky bottom-0 z-10 h-20"> {/* Increased height */}
      <div className="flex justify-between items-stretch h-full">
        <button className={buttonStyle} onClick={onResetHome}>
          <img src={homeIcon} alt="Home" className={iconStyle} />
          <span className={textStyle}>Home</span>
        </button>
        <button className={buttonStyle} onClick={onSortByAbsolut}>
          <img src={euroIcon} alt="Absoluut" className={iconStyle} />
          <span className={textStyle}>Absoluut</span>
        </button>
        <button className={buttonStyle} onClick={onSortByPercentage}>
          <img src={percentIcon} alt="Percentage" className={iconStyle} />
          <span className={textStyle}>Percentage</span>
        </button>
        <button className={buttonStyle} onClick={onResetApplication}> {/* Renamed function */}
          <img src={refreshIcon} alt="Reset" className={iconStyle} />
          <span className={textStyle}>Reset</span>
        </button>
        <button className={buttonStyle} onClick={onShowFilter}>
          <img src={filterIcon} alt="Filter" className={iconStyle} />
          <span className={textStyle}>Filter</span>
        </button>
      </div>
    </footer>
  );
};

export default Footer;
