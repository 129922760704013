import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import { Product } from '../../types/Product';
import JSZip from 'jszip';

interface DataManagerProps {
  onDataFetched: (data: {
    products: Product[];
    index: {
      byId: Map<string, Product>;
      byTitle: Map<string, Product[]>;
      byDiscountAbsolut: Product[];
      byDiscountPercentage: Product[];
      byShopType: Map<string, Product[]>;
      byMainCategory: Map<string, Product[]>;
      byIsBonus: Map<boolean, Product[]>;
    };
  }) => void;
}

const DataManager: React.FC<DataManagerProps> = ({ onDataFetched }) => {
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    const fetchProducts = async () => {
      if (dataFetchedRef.current) return;

      try {
        const zipResponse = await axios.get('https://omfietser.nl/API/index.php', {
          responseType: 'arraybuffer',
        });

        const zip = new JSZip();
        const zipContent = await zip.loadAsync(zipResponse.data);
        const productsJsonContent = await zipContent.file('products.json')?.async('string');

        if (productsJsonContent) {
          const products: Product[] = JSON.parse(productsJsonContent);
          const indexedProducts = createIndex(products);
          console.log('Indexed Products:', indexedProducts); // Log indexed products
          onDataFetched(indexedProducts);
          dataFetchedRef.current = true;
        } else {
          console.error('products.json not found in the ZIP file');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [onDataFetched]);

  const createIndex = (products: Product[]) => {
    const index = {
      byId: new Map<string, Product>(),
      byTitle: new Map<string, Product[]>(),
      byDiscountAbsolut: [...products].sort((a, b) => (b.discountAbsolut || 0) - (a.discountAbsolut || 0)),
      byDiscountPercentage: [...products].sort((a, b) => (a.discountPercentage || 0) - (b.discountPercentage || 0)), // Ascending order
      byShopType: new Map<string, Product[]>(),
      byMainCategory: new Map<string, Product[]>(),
      byIsBonus: new Map<boolean, Product[]>(),
    };

    products.forEach((product) => {
      // Index by ID
      index.byId.set(product._id.$oid, product);

      // Index by Title
      const titleKey = product.title.toLowerCase();
      if (!index.byTitle.has(titleKey)) {
        index.byTitle.set(titleKey, []);
      }
      index.byTitle.get(titleKey)!.push(product);

      // Index by Shop Type
      if (!index.byShopType.has(product.shopType)) {
        index.byShopType.set(product.shopType, []);
      }
      index.byShopType.get(product.shopType)!.push(product);

      // Index by Main Category
      if (!index.byMainCategory.has(product.mainCategory)) {
        index.byMainCategory.set(product.mainCategory, []);
      }
      index.byMainCategory.get(product.mainCategory)!.push(product);

      // Index by Is Bonus
      if (!index.byIsBonus.has(product.isBonus)) {
        index.byIsBonus.set(product.isBonus, []);
      }
      index.byIsBonus.get(product.isBonus)!.push(product);
    });

    console.log('Sorted by discountAbsolut:', index.byDiscountAbsolut.slice(0, 5)); // Log top 5 for discountAbsolut
    console.log('Sorted by discountPercentage:', index.byDiscountPercentage.slice(0, 5)); // Log top 5 for discountPercentage

    return { products, index };
  };

  return null;
};

export default DataManager;